import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons'; // Import Menu icon from Material-UI
import "./styles.css";
import logo from "../../Assets/banner01.png";
import { Link, NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import {  Nav, Container, NavDropdown, Row, Col } from "react-bootstrap";
import LivepetroLogo from "../../Assets/logo.svg";

function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (

    <div>
      <BrowserView>
    <nav className='navpadding'>
      <div className="navbar">

        <img src={LivepetroLogo} className='navbarlogo'/>
        <div className='gap'>
          <a href="#home">Home</a>
          <a href="#storage">Services</a>
          <a href="#about">About</a>
          <a href="#product">Our Products</a>
          <a href="/gallery">Gallery</a>
          <a href="#contact">Contact</a>
        </div>
      </div>
    </nav>
    </BrowserView>
    <MobileView>
    <nav className='navpaddingmob'>
      <div className="navbar">
      <img src={LivepetroLogo} className='navbarlogomob'/>

      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleClick}
          style={{color:'white'}}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}><a href="#home" style={{ color: 'inherit', textDecoration: 'none' }}>Home</a></MenuItem>
          <MenuItem onClick={handleClose}><a href="#storage" style={{ color: 'inherit', textDecoration: 'none' }}>Services</a></MenuItem>
          <MenuItem onClick={handleClose}><a href="#about" style={{ color: 'inherit', textDecoration: 'none' }}>About</a></MenuItem>
          <MenuItem onClick={handleClose}><a href="#product" style={{ color: 'inherit', textDecoration: 'none' }}>Our Products</a></MenuItem>
          <MenuItem onClick={handleClose}><a href="/gallery" style={{ color: 'inherit', textDecoration: 'none' }}>Gallery</a></MenuItem>
          <MenuItem onClick={handleClose}><a href="#contact" style={{ color: 'inherit', textDecoration: 'none' }}>Contact</a></MenuItem>


        </Menu>
      </Toolbar>

      </div>
    </nav>
    </MobileView>
    </div>
  );
};

export default Navbar;