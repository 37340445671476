import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom'; // If using React Router
import { Col, Container, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import 'bootstrap/dist/css/bootstrap.min.css';
import quote from "../../Assets/quote.png";
import bunkering from "../../Assets/bunkering.jpg";
import logistics from "../../Assets/logistics.jpg";
import storage01 from "../../Assets/storage001.png";
import storage02 from "../../Assets/storage002.png";
import storage03 from "../../Assets/storage003.png";
import "./storage.css";
import Aos from "aos";
import "aos/dist/aos.css";
import ImageWithText from "../Imagewithtext/ImageWithText"




function PhotoWithText({ photoUrl, text }) {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    return () => {
      Aos.refresh();
    };
  }, []);

  const overlayStyle = {
    position: 'absolute',
    width: '100%',
    color: 'white',
    padding: '0px',
    textAlign: 'center',
  };

  return (
    <div className="photo-with-text" style={{ position: 'relative', display: 'inline-block' }}>
      <img src={photoUrl} alt="Photo" className='storagephoto' style={{ width: '100%' }} />
      <div className="text-overlay head01" style={overlayStyle}>
        {text}
      </div>
    </div>
  );
}

function Storage() {
  return (
    <div id="storage">
      <BrowserView className='storagebgclr'>
        <Row>
          <div className='headcenter'>
            <h1>Our storage</h1>
          </div>
        </Row>
        <Row xs={12} md={7} lg={12} data-aos="fade-left" data-aos-duration="1000">
          <div className='bgclr01'>
            <Col>
              <div className="app">
                <PhotoWithText photoUrl={storage01} text="Hamriyah Freezone" />
              </div>
            </Col>
            <Col>
              <div className="app">
                <PhotoWithText photoUrl={storage02} text="Jurf Industrial Area" />
              </div>
            </Col>
            <Col>
              <div className="app">
                <PhotoWithText photoUrl={storage03} text="Mina Abdullah" />
              </div>
            </Col>
          </div>
        </Row>
        <Row className='bgyellow'>
          <Col xs={12} md={7} lg={7} className='m-0 p-0' data-aos="fade-left" data-aos-duration="1000">
            <div className='storagepadding'>
              <h1 className='storagetext'>Logistics & Storage</h1>
              <p className='storagetext'>Live petro offering efficient logistics, storage solutions. </p>
              <p className='storagetext'>We are providing reliable services of petroleum products<br/> such as proper loading and unloading 
              of Flexitanks, <br/>ISO tanks and IBS tanks. </p>
            </div>
          </Col>
          <Col xs={12} md={7} lg={5} className='m-0 p-0 animated-image '>
            <img src={logistics} className="photo" alt="Logistics" />
          </Col>
        </Row>
        <Row className='bgwhite'>
          <Col xs={12} md={7} lg={7} className='m-0 p-0'>
            <img src={bunkering} className="photo animated-image " alt="Bunkering" />
          </Col>
          <Col xs={12} md={7} lg={5} className='m-0 p-0' data-aos="fade-right" data-aos-duration="1000">
            <div className='storagepadding'>
              <h1 className='storagetext'>Bunkering</h1>
              <p className='storagetext'>We provide fuel for ships, including the logistics<br/>  of loading and distributing the fuel among available<br/> shipboard resources.</p>
              <p className='storagetext'>We are dealing Intermediate Fuel Oil (IFO) 380 CST <br/>RMG , IFO 180 CST RME and Low Sulfur Marine <br/>Gas Oil (LSMGO) to cater our clients' various <br/>fuel requirements.</p>
            </div>
          </Col>
        </Row>
      </BrowserView>
      <MobileView className='storagebgclrmob'>
        <Row>
          <div className='headcenter'>
            <h1>Our storage</h1>
          </div>
        </Row>
        <div className="p-5 pt-0 pb-2" data-aos="fade-left" data-aos-duration="1000">
          <PhotoWithText photoUrl={storage01} text="Hamriyah Freezone" />
        </div>
        <div className="p-5 pt-0 pb-2" data-aos="fade-right" data-aos-duration="1000">
          <PhotoWithText photoUrl={storage02} text="Jurf Industrial Area" />
        </div>
        <div className="p-5 pt-0" data-aos="fade-left" data-aos-duration="1000">
          <PhotoWithText photoUrl={storage03} text="Mina Abdullah" />
        </div>
        <Row className='bgyellow'>
          <Col xs={12} md={7} lg={7} className='m-0 p-0' data-aos="fade-left" data-aos-duration="1000">
            <div className='p-5'>
              <h1 className='storagetext'>Logistics & Storage</h1>
              <p className='storagetext'>Live petro offering efficient logistics, storage solutions. </p>
              <p className='storagetext'>We are providing reliable services of petroleum products such as proper loading and unloading 
              of Flexitanks, ISO tanks and IBS tanks. </p>
            </div>
          </Col>
          <Col xs={12} md={7} lg={5} className='m-0 p-0'>
            <img src={logistics} className="photo" alt="Logistics" />
          </Col>
        </Row>
        <Row className='bgwhite'>
          <Col xs={12} md={7} lg={5} className='m-0 p-0' data-aos="fade-right" data-aos-duration="1000">
            <div className='p-5'>
              <h1 className='storagetext'>Bunkering</h1>
              <p className='storagetext'>We provide fuel for ships, including the logistics of loading and distributing the fuel among available shipboard resources.</p>
              <p className='storagetext'>We are dealing Intermediate Fuel Oil (IFO) 380 CST RMG , IFO 180 CST RME and Low Sulfur Marine Gas Oil (LSMGO) to cater our clients' various fuel requirements.</p>
            </div>
          </Col>
          <Col xs={12} md={7} lg={7} className='m-0 p-0'>
            <img src={bunkering} className="photo" alt="Bunkering" />
          </Col>
        </Row>
      </MobileView>
    </div>
  );
}

export default Storage;
