import React, { useState , useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserView, MobileView } from "react-device-detect";
import top_curve from "../../Assets/top_curve.png";
import banner01 from "../../Assets/banner01.png";
import Aos from "aos";
import "aos/dist/aos.css";

import baseoil from "../../Assets/p1.png";
import hidrocarbon from "../../Assets/p2.png";
import fueloil from "../../Assets/p3.png";
import other from "../../Assets/p7.png";
import blackoil from "../../Assets/p6.png";
import gasoil from "../../Assets/p4.png";
import aromaticoil from "../../Assets/p5.png";
import lubricants from "../../Assets/p8.png";
import Mho from "../../Assets/p9.png";

import baseoilHover from "../../Assets/2.png";
import hydrocarbonHover from "../../Assets/9.png";
import fueloilHover from "../../Assets/3.png";
import otherHover from "../../Assets/8.png";
import blackoilHover from "../../Assets/5.png";
import gasoilHover from "../../Assets/1.png";
import aromaticoilHover from "../../Assets/4.png"; 
import lubricantsHover from "../../Assets/7.png"; 
import MhoHover from "../../Assets/9.png"; 
// "https://www.canva.com/design/DAGE6fy7O8U/725sPMaLRBmLsgXeUY3dVQ/edit?utm_content=DAGE6fy7O8U&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"

import "./ourproducts.css";

function Ourproducts() {
  const [gasoilHovered, setGasoilHovered] = useState(false);
  const [baseoilHovered, setBaseoilHovered] = useState(false);
  const [fueloilHovered, setFueloilHovered] = useState(false);
  const [aromaticoilHovered, setAromaticoilHovered] = useState(false);
  const [blackoilHovered, setBlackoilHovered] = useState(false);
  const [hydrocarbonHovered, setHydrocarbonHovered] = useState(false);
  const [lubricantsHovered, setLubricantsHovered] = useState(false);
  const [otherHovered, setOtherHovered] = useState(false);
  const [MhoHovered, setMhoHovered] = useState(false);
  // Add similar state variables for other products
  useEffect(() => {
    Aos.init({ duration: 2000 }); // You can adjust the duration as per your preference
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
    return () => {
      Aos.refresh();
    };
  }, []);


  const handleGasoilMouseEnter = () => {
    setGasoilHovered(true);
  };

  const handleGasoilMouseLeave = () => {
    setGasoilHovered(false);
  };

  const handleBaseoilMouseEnter = () => {
    setBaseoilHovered(true);
  };

  const handleBaseoilMouseLeave = () => {
    setBaseoilHovered(false);
  };

  const handleFueloilMouseEnter = () => {
    setFueloilHovered(true);
  };

  const handleFueloilMouseLeave = () => {
    setFueloilHovered(false);
  };

  const handleAromaticoilMouseEnter = () => {
    setAromaticoilHovered(true);
  };

  const handleAromaticoilMouseLeave = () => {
    setAromaticoilHovered(false);
  };

  const handleBlackoilMouseEnter = () => {
    setBlackoilHovered(true);
  };

  const handleBlackoilMouseLeave = () => {
    setBlackoilHovered(false);
  };

  const handleHydrocarbonMouseEnter = () => {
    setHydrocarbonHovered(true);
  };

  const handleHydrocarbonMouseLeave = () => {
    setHydrocarbonHovered(false);
  };

  const handleLubricantsMouseEnter = () => {
    setLubricantsHovered(true);
  };

  const handleLubricantsMouseLeave = () => {
    setLubricantsHovered(false);
  };


  const handleOtherMouseEnter = () => {
    setOtherHovered(true);
  };

  const handleOtherMouseLeave = () => {
    setOtherHovered(false);
  };

  const handleMhoMouseEnter = () => {
    setMhoHovered(true);
  };

  const handleMhoMouseLeave = () => {
    setMhoHovered(false);
  };

  const [isHovered, setIsHovered] = useState(false);
  const handleClick = () => {
    window.open("https://wa.me/971552400992"); // Opens the link in a new tab
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  return (
    <div id="product" className='xhidden p-0 m-0' >
      <BrowserView>
        <div className='headcenterblack'>
          <h1>Products</h1>
        </div>
        <Row className='p-0 m-0' xs={12} md={12} lg={12}  data-aos="fade-right" data-aos-duration="1000">
          <div className='products-container p-0 m-0'>
            <div className='columnbg  p-0 m-0'>
              <Col xs={12} md={3} lg={3} className='m-0 p-0'>
                <div className="background8" onMouseEnter={handleGasoilMouseEnter} onMouseLeave={handleGasoilMouseLeave} onClick={handleClick} style={{ cursor: 'pointer' }}>
                  <img src={gasoilHovered ? gasoilHover : gasoil} className='productphoto2' alt="Gas Oil"/>
                  <h1 className="head1 p-2">GAS OIL</h1>
                </div>
              </Col>
              <Col xs={12} md={3} lg={3} className='m-0 p-0'>
                <div className="background8" onMouseEnter={handleBaseoilMouseEnter} onMouseLeave={handleBaseoilMouseLeave} onClick={handleClick} style={{ cursor: 'pointer' }}>
                  <img src={baseoilHovered ? baseoilHover : baseoil} className='productphoto2' alt="Base Oil"/>
                  <h1 className="head1 p-2">BASE OIL</h1>
                </div>
              </Col>
              <Col xs={12} md={3} lg={3} className='m-0 p-0'>
                <div className="background8" onMouseEnter={handleFueloilMouseEnter} onMouseLeave={handleFueloilMouseLeave} onClick={handleClick} style={{ cursor: 'pointer' }}>
                  <img src={fueloilHovered ? fueloilHover : fueloil} className='productphoto2' alt="Fuel Oil"/>
                  <h1 className="head1 p-2">FUEL OIL</h1>
                </div>
              </Col>
              <Col xs={12} md={3} lg={3} className='m-0 p-0'>
                <div className="background8" onMouseEnter={handleAromaticoilMouseEnter} onMouseLeave={handleAromaticoilMouseLeave} onClick={handleClick} style={{ cursor: 'pointer' }}>
                  <img src={aromaticoilHovered ? aromaticoilHover : aromaticoil} className='productphoto2' alt="Aromatic Oil"/>
                  <h1 className="head1 p-2">AROMATIC OIL</h1>
                </div>
              </Col>

          </div>
        </div>
      </Row>
      <Row className='pt-4 m-0' xs={12} md={12} lg={12}  data-aos="fade-left" data-aos-duration="1000">
        <div className='products-container p-0 m-0'>
          <div className='columnbg'>
          <Col xs={12} md={3} lg={3} className='m-0 p-0'>
              <div className="background8" onMouseEnter={handleBlackoilMouseEnter} onMouseLeave={handleBlackoilMouseLeave} onClick={handleClick} style={{ cursor: 'pointer' }}>
                <img src={blackoilHovered ? blackoilHover : blackoil} className='productphoto2' alt="Black Oil"/>
                <h1 className="head1 p-2">BLACK OIL</h1>
              </div>
            </Col>

            <Col xs={12} md={3} lg={3} className='m-0 p-0'>
              <div className="background8" onMouseEnter={handleHydrocarbonMouseEnter} onMouseLeave={handleHydrocarbonMouseLeave} onClick={handleClick} style={{ cursor: 'pointer' }}>
                <img src={hydrocarbonHovered ? hydrocarbonHover : hidrocarbon} className='productphoto2' alt="Hydrocarbon"/>
                <h1 className="head1 p-2">MHO</h1>
              </div>
            </Col>

            <Col xs={12} md={3} lg={3} className='m-0 p-0'>
              <div className="background8" onMouseEnter={handleLubricantsMouseEnter} onMouseLeave={handleLubricantsMouseLeave} onClick={handleClick} style={{ cursor: 'pointer' }}>
                <img src={lubricantsHovered ? lubricantsHover : lubricants} className='productphoto2' alt="Lubricants"/>
                <h1 className="head1 p-2">LUBRICANTS</h1>
              </div>
            </Col>

            <Col xs={12} md={3} lg={3} className='m-0 p-0'>
              <div className="background8" onMouseEnter={handleOtherMouseEnter} onMouseLeave={handleOtherMouseLeave} onClick={handleClick} style={{ cursor: 'pointer' }}>
                <img src={otherHovered ? otherHover : other} className='productphoto2' alt="Other"/>
                <h1 className="head1 p-2">OTHER</h1>
              </div>
            </Col>
          </div>
        </div>
      </Row>
      {/* <Row className='pt-4 m-0' xs={12} md={12} lg={12}  data-aos="fade-right" data-aos-duration="1000">
        <div className='products-container p-0 m-0'>
          <div className='columnbg'>
          <Col xs={12} md={12} lg={12} className='m-0 p-0'>
              <div className="background8" onMouseEnter={handleMhoMouseEnter} onMouseLeave={handleMhoMouseLeave} onClick={handleClick} style={{ cursor: 'pointer' }}>
                <img src={MhoHovered ? MhoHover : Mho} className='productphoto2' alt="Mho"/>
                <h1 className="head1 p-2">MHO</h1>
              </div>
            </Col>

          </div>
        </div>
      </Row> */}

    <div>
        <img src={top_curve} className='imagepng'/>
    </div>
    <Row xs={12} md={7} lg={12} className='bgcolour'>
      <center   data-aos="fade-down" data-aos-duration="1000">
        <span className='headcenter2' style={{ color: 'rgb(255, 225, 0)' }}>Live Business Group </span>
        <span className='headcenter2' style={{ color: 'white' }}> Head office <br/>in UAE. The Branches are Kuwait and KSA.</span>
      </center>
        <div className='productsbgclr'>
                <Col data-aos="fade-right" data-aos-duration="1000">
                    <div className="background6">
                    <img src={banner01} className='productimg'/>
                    <h1 className="head1">Live Energy FZC</h1>
                    <h2 className="head3">Hamriyah Freezone,</h2>
                    <p className="head3">Sharjah,</p>
                    </div>
                </Col>
                <Col data-aos="fade-right" data-aos-duration="1000">
                    <div className="background6">
                    <img src={banner01} className='productimg'/>
                    <h1 className="head1">LiveFuelLLC</h1>
                    <h2 className="head3">Jurf Industrial Area,</h2>
                    <p className="head3">UAE Ajman,</p>
                    </div>
                </Col>
                <Col data-aos="fade-left" data-aos-duration="1000">
                    <div className="background6">
                    <img src={banner01} className='productimg'/>
                    <h1 className="head1">Live Petrochemicals FZE</h1>
                    <h2 className="head3">Hamriyah Freezone,</h2>
                    <p className="head3">UAE Sharjah,</p>
                    </div>
                </Col>
                <Col data-aos="fade-left" data-aos-duration="1000">
                    <div className="background6">
                    <img src={banner01} className='productimg'/>
                    <h1 className="head1">Live National Factory</h1>
                    <h2 className="head3">Shuwaikh</h2>
                    <p className="head3">Kuwait</p>
                    </div>
                </Col>
                </div>
            </Row>
      </BrowserView>

      <MobileView>
      <div className='headcenterblack'>
          <h1>Products</h1>
      </div>
      <Row>
        <div className='columnbgmob'>
                <Col className="grid-item"xs={6} md={6} lg={6}>
                <div className="background8mob" onMouseEnter={handleGasoilMouseEnter} onMouseLeave={handleGasoilMouseLeave} style={{ cursor: 'pointer' }}>
                  <img src={gasoilHovered ? gasoilHover : gasoil} className='productphoto2' alt="Gas Oil"/>
                  <h1 className="head1mob p-2"> GAS OIL<svg xmlns="http://www.w3.org/2000/svg" className='buttoncenter' viewBox="0 0 24 24" width="24" height="24" onClick={handleClick}><path d="M0 0h24v24H0z" fill="none"/><path d="M10 17l5-5-5-5v10z"/></svg></h1>
                </div>

                </Col>
                <Col className="grid-item"xs={6} md={6} lg={6}>
                <div className="background8mob" onMouseEnter={handleBaseoilMouseEnter} onMouseLeave={handleBaseoilMouseLeave} style={{ cursor: 'pointer' }}>
                  <img src={baseoilHovered ? baseoilHover : baseoil} className='productphoto2' alt="Base Oil"/>
                  <h1 className="head1mob p-2">BASE OIL<svg xmlns="http://www.w3.org/2000/svg" className='buttoncenter' viewBox="0 0 24 24" width="24" height="24" onClick={handleClick}><path d="M0 0h24v24H0z" fill="none"/><path d="M10 17l5-5-5-5v10z"/></svg></h1>
                </div>
                </Col>
                </div>
      </Row>
      <Row>
        <div className='columnbgmob'>
                <Col className="grid-item"xs={6} md={6} lg={6}>
                <div className="background8mob" onMouseEnter={handleFueloilMouseEnter} onMouseLeave={handleFueloilMouseLeave} style={{ cursor: 'pointer' }}>
                  <img src={fueloilHovered ? fueloilHover : fueloil} className='productphoto2' alt="Fuel Oil"/>
                  <h1 className="head1mob p-2">FUEL OIL<svg xmlns="http://www.w3.org/2000/svg" className='buttoncenter' viewBox="0 0 24 24" width="24" height="24" onClick={handleClick}><path d="M0 0h24v24H0z" fill="none"/><path d="M10 17l5-5-5-5v10z"/></svg></h1>
                </div>
                </Col>
                <Col className="grid-item"xs={6} md={6} lg={6}>
                <div className="background8mob" onMouseEnter={handleAromaticoilMouseEnter} onMouseLeave={handleAromaticoilMouseLeave} style={{ cursor: 'pointer' }}>
                  <img src={aromaticoilHovered ? aromaticoilHover : aromaticoil} className='productphoto2' alt="Aromatic Oil"/>
                  <h1 className="head1mob p-2">AROMATIC OIL<svg xmlns="http://www.w3.org/2000/svg" className='buttoncenter' viewBox="0 0 24 24" width="24" height="24" onClick={handleClick}><path d="M0 0h24v24H0z" fill="none"/><path d="M10 17l5-5-5-5v10z"/></svg></h1>
                </div>
                </Col>
                </div>
      </Row>
      <Row>
        <div className='columnbgmob'>
                <Col className="grid-item"xs={6} md={6} lg={6}>
                <div className="background8mob" onMouseEnter={handleBlackoilMouseEnter} onMouseLeave={handleBlackoilMouseLeave} style={{ cursor: 'pointer' }}>
                <img src={blackoilHovered ? blackoilHover : blackoil} className='productphoto2' alt="Black Oil"/>
                <h1 className="head1mob p-2">BLACK OIL<svg xmlns="http://www.w3.org/2000/svg" className='buttoncenter' viewBox="0 0 24 24" width="24" height="24" onClick={handleClick}><path d="M0 0h24v24H0z" fill="none"/><path d="M10 17l5-5-5-5v10z"/></svg></h1>
              </div>
                </Col>
                <Col className="grid-item"xs={6} md={6} lg={6}>
                <div className="background8mob" onMouseEnter={handleHydrocarbonMouseEnter} onMouseLeave={handleHydrocarbonMouseLeave} style={{ cursor: 'pointer' }}>
                <img src={hydrocarbonHovered ? hydrocarbonHover : hidrocarbon} className='productphoto2' alt="Hydrocarbon"/>
                <h1 className="head1mob p-2">MHO<svg xmlns="http://www.w3.org/2000/svg" className='buttoncenter' viewBox="0 0 24 24" width="24" height="24" onClick={handleClick}><path d="M0 0h24v24H0z" fill="none"/><path d="M10 17l5-5-5-5v10z"/></svg></h1>
              </div>
                </Col>
                </div>
      </Row>
      <Row>
        <div className='columnbgmob'>
              <Col className="grid-item"xs={6} md={6} lg={6}>
                <div className="background8mob" onMouseEnter={handleLubricantsMouseEnter} onMouseLeave={handleLubricantsMouseLeave} style={{ cursor: 'pointer' }}>
                <img src={lubricantsHovered ? lubricantsHover : lubricants} className='productphoto2' alt="Lubricants"/>
                <h1 className="head1mob p-2">LUBRICANTS<svg xmlns="http://www.w3.org/2000/svg" className='buttoncenter' viewBox="0 0 24 24" width="24" height="24" onClick={handleClick}><path d="M0 0h24v24H0z" fill="none"/><path d="M10 17l5-5-5-5v10z"/></svg></h1>
              </div>
                </Col>
                <Col className="grid-item"xs={6} md={6} lg={6}>
                <div className="background8mob" onMouseEnter={handleOtherMouseEnter} onMouseLeave={handleOtherMouseLeave} style={{ cursor: 'pointer' }}>
                <img src={otherHovered ? otherHover : other} className='productphoto2' alt="Other"/>
                <h1 className="head1mob p-2">OTHER<svg xmlns="http://www.w3.org/2000/svg" className='buttoncenter' viewBox="0 0 24 24" width="24" height="24" onClick={handleClick}><path d="M0 0h24v24H0z" fill="none"/><path d="M10 17l5-5-5-5v10z"/></svg></h1>
              </div>
                </Col>

                </div>
      </Row>
      {/* <Row>
        <div className='columnbgmob'>
                <Col className="grid-item"xs={6} md={6} lg={6}>
                <div className="background8mob" onMouseEnter={handleMhoMouseEnter} onMouseLeave={handleMhoMouseLeave} style={{ cursor: 'pointer' }}>
                <img src={MhoHovered ? MhoHover : Mho} className='productphoto2' alt="Mho"/>
                <h1 className="head1mob p-2">MHO<svg xmlns="http://www.w3.org/2000/svg" className='buttoncenter' viewBox="0 0 24 24" width="24" height="24" onClick={handleClick}><path d="M0 0h24v24H0z" fill="none"/><path d="M10 17l5-5-5-5v10z"/></svg></h1>
              </div>
                </Col>

        </div>
      </Row> */}

    <div>
        <img src={top_curve} className='imagepng'/>
    </div>
    <Row xs={12} md={7} lg={12} className='bgcolour'>
      <center data-aos="fade-down" data-aos-duration="1000">
        <span className='headcenter2mob' style={{ color: 'rgb(255, 225, 0)' }}>Live Business Group </span>
        <span className='headcenter2mob' style={{ color: 'white' }}> Head office in UAE. The Branches are Kuwait and KSA.</span>
      </center>
      <div className='productsbgclrmob'>
        <Row xs={12} md={12} lg={12} className="grid-container p-5 pt-3 m-0 w-100">
                <Row data-aos="fade-left" data-aos-duration="1000">
                    <div className="background6">
                    <img src={banner01} className='productimg'/>
                    <h1 className="head1">Live Energy FZC</h1>
                    <h2 className="head3">Hamriyah Freezone,</h2>
                    <p className="head3">Sharjah,</p>
                    </div>
                </Row>
                <Row data-aos="fade-right" data-aos-duration="1000">
                    <div className="background6">
                    <img src={banner01} className='productimg'/>
                    <h1 className="head1">Live Fuel LLC</h1>
                    <h2 className="head3">Jurf Industrial Area,</h2>
                    <p className="head3">UAE Ajman,</p>
                    </div>
                </Row>
                <Row data-aos="fade-left" data-aos-duration="1000">
                    <div className="background6">
                    <img src={banner01} className='productimg'/>
                    <h1 className="head1">Live Petrochemicals FZE</h1>
                    <h2 className="head3">Hamriyah Freezone,</h2>
                    <p className="head3">UAE Sharjah,</p>
                    </div>
                </Row>
                <Row data-aos="fade-right" data-aos-duration="1000">
                    <div className="background6">
                    <img src={banner01} className='productimg'/>
                    <h1 className="head1">Live National Factory</h1>
                    <h2 className="head3">Shuwaikh</h2>
                    <p className="head3">Kuwait</p>
                    </div>
                </Row>
              </Row>

              </div>
            </Row>
      </MobileView>

    </div>
  );
}

export default Ourproducts;