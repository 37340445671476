import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { BrowserView, MobileView } from 'react-device-detect';
import { Row } from "react-bootstrap";
import Navbar from "../navbar/navbar";
import whatsappIcon from "../../Assets/whatsapp.png";
import "./landing.css";
import homebg1 from "../../Assets/banner03.jpg";
import homebg2 from "../../Assets/banner02.png";

const Landing = () => {
    const [zoomed, setZoomed] = useState(false);

    const zoomInProps = useSpring({
        transform: zoomed ? 'scale(1)' : 'scale(0)',
        opacity: zoomed ? 1 : 0,
        config: { duration: 2000 }
    });

    useEffect(() => {
        setZoomed(true);
    }, []);

    return (
        <div id="home">
            <BrowserView>
                <div className="home">
                    <Navbar />
                    <Row>
                        <div className="para">
                            <animated.div style={zoomInProps}>
                                <p>Build Superior Quality<br />Industrial Solutions</p>
                                <button className="bn" onClick={() => window.open("https://wa.me/971552400992")}>CONTACT US</button>
                            </animated.div>
                        </div>
                    </Row>
                </div>
            </BrowserView>
            <MobileView>
                <div className="homemob">
                    <Navbar />
                    <Row>
                        <div className="paramob">
                            <animated.div style={zoomInProps}>
                                <p>Build Superior Quality<br />Industrial Solutions</p>
                                <button className="bn" onClick={() => window.open("https://wa.me/971552400992")}>CONTACT US</button>
                            </animated.div>
                        </div>
                    </Row>
                </div>
            </MobileView>
            <button className="whatsapp-button" onClick={() => window.open("https://wa.me/971552400992")}>
                <img src={whatsappIcon} alt="WhatsApp" />
            </button>
        </div>
    )
}

export default Landing;
