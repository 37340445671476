import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/home/home";
import About from "./Components/about/about";
import Storage from "./Components/storages/storage";
import Gallery from "./Components/gallery/gallery"

import ScrollToTop from "./Components/ScrollToTop";

const Entrypoint = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/" element={<About />} />
        <Route exact path="/" element={<Storage />} />
        <Route exact path="/gallery" element={<Gallery />} />


      </Routes>
    </Router>
  );
};
export default Entrypoint;
