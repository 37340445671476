import React from "react";
import Navbar from "../navbar/navbar";
import About from "../about/about";
import Storage from "../storages/storage"
import Ourproducts from "../ourproducts/ourproducts" 
import Contact from "../contactus/contactus"
import Footer from "../footer/footer";
import Landing from "../landing/landing";

const Home = () => {
    return(
        <div className="p-0 xhidden">

            <Landing/>
            <About/>
            <Storage/>
            <Ourproducts/>
            <Contact/>
            <Footer/>

            
        </div>
    )
}

export default Home;