import React from 'react';
import { Link } from 'react-router-dom'; // If using React Router
import "./footer.css";
import { BrowserView, MobileView } from 'react-device-detect';

const Footer = () => {
  return (
    <div>
      <BrowserView>
      <footer className='footer'>
          <a className='footertext'>Copyright©2024 Live Business Group..All rights reserved</a>
        <div className='gap footeralign'>
        
          <a href="#about">About</a>
          <a href="#storage">Services</a>
          <a href="#product">Our Products</a>
        </div>
      </footer>
      </BrowserView>
      <MobileView>
      <footer className='footer'>
          <a className='footertextmob '>Copyright©2024 Live Business Group..All rights reserved</a>


      </footer>
      </MobileView>
      </div>
  );
};

export default Footer;