import { React, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import Navbar from "../navbar/navbar";
import "./contactus.css";
import about02 from "../../Assets/about02.jpg";
import LivepetroLogo from "../../Assets/logo.svg";
import homebg1 from "../../Assets/top_yellow.png";
import instagram from "../../Assets/in_f.svg";
import facebook from "../../Assets/fb_f.svg";
import twitter from "../../Assets/tw_f.svg";
import linkedin from "../../Assets/in_f.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import emailjs from "@emailjs/browser";

const Contactus = (props) => {
    const { ln } = props;
    useEffect(() => {
      Aos.init({ duration: 2000 });
    }, []);
  
    function isNumeric(value) {
      return /^-?\d+$/.test(value);
    }
    useEffect(() => {
      Aos.init({ duration: 2000 }); // You can adjust the duration as per your preference
    }, []);
  
    useEffect(() => {
      Aos.init({ duration: 2000 });
      return () => {
        Aos.refresh();
      };
    }, []);
  
    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
      const data = new FormData(e.target);
      const name = data.get("name");
      const email = data.get("email");
      const message = data.get("message");
      console.log(name);
  
      if (name != "" && email != "" && message != "") {
         {
          emailjs
            .sendForm(
              "service_7zie54t",
              "template_xmgs4pj",
              form.current,
              "0SwenmBudYB6rYsBF"
            )
            .then(
              (result) => {
                alert("Message sent !");
                document.getElementById("form").reset();
                console.log(result.text);
              },
              (error) => {
                console.log(error.text);
              }
            );
        }
      } else {
        alert("Please fill out all details !");
      }
    };
    return(

      <div id="contact" className="">
      <BrowserView>
        <div className="p-5 m-5">
        <Row className="contactbgclr">

            <Col className="section2 p-5" xs={6} md={6} lg={6}>
            <h1 className="contacttext1">Reach Us</h1>
            <h2 className="contacttext2">If you like what you see,<br/>let's work together</h2>
            <p className="contacttext3">I bring rapid solutions to make the life of my clients easier.<br/>Have any questions? Reach out to me from this contact<br/>form and I will get back to you shortly.</p>
            </Col>

            <Col className="section2 p-5" xs={6} md={6} lg={6}>
              <Form ref={form} onSubmit={sendEmail} id="form">
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Control type="text" placeholder="Name" name="name" />
                  </Form.Group>

                    <Form.Group className="mb-3" controlId="formGridEmail">
                        <Form.Control placeholder="Email" type="email" name="email" />
                    </Form.Group>
                </Row>



                <Form.Group className="mb-3" controlId="formGridAddress2">
                  <Form.Control placeholder="Your Message" name="message" />
                </Form.Group>

                <Button

                  type="submit"
                  className="w-100"
                  style={{ backgroundColor: "#1c3464" }}
                >
                  SEND MESSAGE
                </Button>
              </Form>
            </Col>

        </Row>    
        <Row className="contactgap">
            <Col data-aos="fade-right" data-aos-duration="1000">
              <div className="background7">
                <img src={LivepetroLogo} className="contactuslogo" alt="Livepetro Logo" />
              </div>
            </Col>
            <Col data-aos="fade-right" data-aos-duration="1000">
              <div className="background7">
                <h1 className="contacttext2">Address</h1>
                <p className="contacttext3">Hamriya Freezone</p>
                <h3 className="contacttext3">Sharjah, UAE</h3>
                <p className="contacttext3">Jurf Industrial Area</p>
                <h3 className="contacttext3">Ajman, UAE</h3>
              </div>
            </Col>
            <Col data-aos="fade-right" data-aos-duration="1000">
              <div className="background7">
                <h1 className="contacttext2">Contact Us</h1>
                <p className="contacttext3">info@livepetro.com</p>
                <h1 className="contacttext3">+971552400992</h1>
                <h1 className="contacttext3">+97165565472</h1>
              </div>
            </Col>
            <Col data-aos="fade-right" data-aos-duration="1000">
              <div className="background7">
                <h1 className="contacttext2">Follow Us</h1>
                <a href="https://www.facebook.com/">
                  <img src={facebook} width={40} alt="Facebook" />
                </a>
                <a href="https://instagram.com/">
                  <img src={instagram} width={40} alt="Instagram" />
                </a>
                <a href="https://twitter.com/">
                  <img src={twitter} width={40} alt="Twitter" />
                </a>
                <a href="https://www.linkedin.com/">
                  <img src={linkedin} width={40} alt="LinkedIn" />
                </a>
              </div>
            </Col>
          </Row>
        </div>
        </BrowserView>
            <MobileView>
            <Row className="contactbgclr">
                <Col className="section2" xs={12} md={6} lg={6}>
                <h1 className="contacttext1">Reach Us</h1>
                <h2 className="contacttext2">If you like what you see,<br/>let's work together</h2>
                <p className="contacttext3">I bring rapid solutions to make the life of my clients easier.<br/>Have any questions? Reach out to me from this contact<br/>form and I will get back to you shortly.</p>
                </Col>
    
                <Col className="section2" xs={12} md={6} lg={6}>
                  <Form ref={form} onSubmit={sendEmail} id="form">
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Control type="text" placeholder="Name" name="name" />
                      </Form.Group>
    
                        <Form.Group className="mb-3" controlId="formGridEmail">
                            <Form.Control placeholder="Email" type="email" name="email" />
                        </Form.Group>
                    </Row>
    
    
    
                    <Form.Group className="mb-3" controlId="formGridAddress2">
                      <Form.Control placeholder="Your Message" name="message" />
                    </Form.Group>
    
                    <Button
    
                      type="submit"
                      className="w-100"
                      style={{ backgroundColor: "#1c3464" }}
                    >
                      SEND MESSAGE
                    </Button>
                  </Form>
                </Col>
    
            </Row>    
            <div className="w-100 p-0 m-0 contactgapmob">
              <Row data-aos="fade-right" data-aos-duration="1000">
                <Col xs={6} md={7} lg={12} >
                    <div className="background7">
                    <img src={LivepetroLogo} className='contactuslogo'/>
                    </div>
                </Col>
                <Col xs={6} md={7} lg={12}>
                    <div className="background7">
                      <h1 className="contacttext2">Follow Us</h1>
                      <a href="https://www.facebook.com/">
                      <img src={facebook} width={30} />
                      </a>
                      <a href="https://instagram.com/">
                      <img src={instagram} width={30} />
                      </a>
                      <a href="https://twitter.com/">
                      <img src={twitter} width={30} />
                      </a>
                      <a href="https://www.linkedin.com/ ">
                      <img src={linkedin} width={30} />
                      </a>
                    </div>
                </Col>
                </Row>
                <Row data-aos="fade-left" data-aos-duration="1000">

                <Col xs={6} md={4} lg={4}>
                    <div className="background7">
                    <h1 className="contacttext2">ContactUs</h1>
                    <p className="contacttext3">info@livepetro.com</p>
                    <h1 className="contacttext3">+971552400992</h1>
                    <h1 className="contacttext3">+97165565472</h1>
                    </div>
                </Col>
                <Col xs={6} md={7} lg={12}>
                    <div className="background7">
    
                    <h1 className="contacttext2">Address</h1>
                    <p className="contacttext3">
                      Hamriya Freezone<br/>
                      Sharjah,UAE<br/>
                      Jurf Industrial Area<br/>
                      Ajman,UAE
                      </p>
                    </div>
                </Col>
                </Row>
                </div>
          </MobileView>
          </div>
    )
}

export default Contactus;