import React, { useState , useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import Navbar from "../navbar/navbar";
import { BrowserView, MobileView } from "react-device-detect";
import "./about.css";
import about01 from "../../Assets/about.jpg";
import about02 from "../../Assets/about02.jpg";
import quote from "../../Assets/quote.png";
import homebg1 from "../../Assets/banner03.jpg";
import homebg2 from "../../Assets/banner02.png";
import Aos from "aos";
import "aos/dist/aos.css";


const About = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 }); // You can adjust the duration as per your preference
      }, []);
    
      useEffect(() => {
        Aos.init({ duration: 2000 });
        return () => {
          Aos.refresh();
        };
      }, []);
    
    return(
        <div id="about" className="aboutbg w-100 p-0 m-0">
            <BrowserView>
                <div className="padding">
                    <Row xs={12} md={7} lg={12} className="p-5 m-5">
                        <Col xs={12} md={5} lg={4} className="fadeIn">
                            <div>
                                <img src={about01} className="photoabout animated-image p-2"/>
                            </div>
                        </Col>
                        <Col xs={12} md={7} lg={8} data-aos="fade-left" data-aos-duration="1000">
                            <div className=''>
                                <p className="headabout">WELCOME TO</p>
                                <p className="head2">Live Business Group</p>
                                <p className="head4">Live Business Group is a Professionally managed business conglomerate having diversiﬁed business interests in Asia and Africa.</p>
                                <p className="head03">The Company, which deals in supplying and trading different kinds of Petroleum Products, Petrochemicals and Minerals for more than a decade with a dedicated client base in various geographic areas Such as Asia, Africa, Euorope.In a short span of time, the group has created focused Services.</p>
                                <p className="head03">Spurred by business experience.As we committed,Live Group will strive to bring the latest innovations and offers the best value to all our customers</p>
                            </div>
                        </Col>
                    </Row>
                    <Row  data-aos="fade-down" data-aos-duration="1000">
                        <div className="aligncenter">
                            <div className='headblock w-75 center'>
                                <img src={quote}/>
                                <h1>Leadership is the capacity <br/>to translate vision into reality <br/>ALI | Chairman</h1>
                            </div>
                        </div>
                    </Row>
                </div>
            </BrowserView>
            <MobileView>
                <div>
                    <div className="p-5 pt-4" data-aos="fade-right" data-aos-duration="1000">
                        <div>
                            <h1 className="headmob ">WELCOME TO</h1>
                            <p className="head2mob">Live Business Group</p>
                        </div>
                        <p className="head4">Live Business Group is a Professionally managed business conglomerate having diversiﬁed business interests in Asia and Africa.</p>
                        <p className="head03">The Company, which deals in supplying and trading different kinds of Petroleum Products, Petrochemicals and Minerals for more than a decade with a dedicated client base in various geographic areas Such as Asia, Africa, Euorope.In a short span of time, the group has created focused Services.</p>
                        <p className="head03">Spurred by business experience.As we committed,Live Group will strive to bring the latest innovations and offers the best value to all our customers</p>
                    </div>
                    <div className='photoflex fadeIn'>
                        <img src={about01} className="w-100"/> 
                    </div>
                    <Row  data-aos="fade-down" data-aos-duration="1000">
                        <div className="aligncenter">
                            <div className='headblockmob w-100 center'>
                                <img src={quote}/>
                                <h1>Leadership is the capacity <br/>to translate vision into reality <br/>ALI | Chairman</h1>
                            </div>
                        </div>
                    </Row>
                </div>
            </MobileView>
        </div>
    )
}

export default About;
